import { useEffect } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { startExamPaper } from "../../../services/examAttempt/service";
import { useAtom } from "jotai";
import { examPaper } from "../../../jotaiStore/exam";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { currentUser } from "../../../jotaiStore/user";

export function ModalBox({
  routePath,
  id,
  open,
  setOpen,
  count,
  setCount,
  startCounter,
  setStartCounter,
  getAttemptedCount,
}) {
  const router = useNavigate();
  const [user] = useAtom(currentUser);
  const [exampPaperResult, setExampPaperResult] = useAtom(examPaper);
  const startExampTestDetailsFun = () => {
    startExamPaper(id)
      .then((res) => {
        setExampPaperResult(res?.data?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (startCounter) {
        setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      }
      if (count === 0) {
        startExampTestDetailsFun();
        router(routePath);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startCounter, count]);

  const totalAttempts = user?.packageBought === "rcic-basic" ? 3 : 2;

  return (
    <div>
      <div>
        <button
          onClick={() => {
            if (getAttemptedCount?.data?.count === totalAttempts) {
              return toast.error("Your attempted limit has been reached.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                role: "alert",
              });
            }
            setOpen(true);
          }}
          className="btn btn-primary px-5 py-2"
        >
          Start
        </button>

        <Modal
          open={open}
          showCloseIcon={startCounter ? false : true}
          animationDuration={500}
          role="dialog"
          closeOnEsc={startCounter ? false : true}
          closeOnOverlayClick={startCounter ? false : true}
          onClose={() => {
            setOpen(false);
          }}
          center
        >
          <div className="rounded-md ">
            {startCounter ? (
              <div className="p-10 font-mulish text-xl ">
                <p>Your Test is about to start in {count} seconds.</p>
              </div>
            ) : (
              <>
                <div className="sm:max-w-[425px]">
                  <div>
                    <div className="text-xl font-bold text-[#ec4141]">
                      Warning:
                    </div>
                    <div className="my-3 font-semibold">
                      Do not Refresh your Page . You are about to start a test.
                    </div>
                    <div className="my-3 font-mulish">
                      Please be advised that refreshing the page during the test
                      will result in data loss and could disrupt your progress.
                      Ensure you have read all instructions and are ready to
                      begin the test. If you experience technical issues, please
                      contact support for assistance.
                    </div>
                  </div>

                  <div>
                    <button
                      className="  bg-primary px-2  py-1 text-white fs-6"
                      onClick={() => {
                        setStartCounter(true);
                      }}
                    >
                      Begin test
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}
