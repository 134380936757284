"use client";

import React, { useEffect, useState } from "react";
import style from "./index.module.css";
import { BsRecord, BsRecordFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "./index.module.css";
export const AnswerModule = ({
  getTestPaperData,
  selectedOptions,
  exampPaperResult,
}) => {
  //to get current Question index
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const router = useNavigate();
  return (
    <div>
      <>
        <div class="shadow d-flex justify-content-between align-items-center">
          <img
            src="https://2brcic.ca/wp-content/uploads/2020/12/logo_transparent_background-768x332.png"
            alt="2BRCIC"
            width="200"
            height="80"
          />
          <div className="flex-1 font-mulish text-2xl text-black">
            Your scored {exampPaperResult?.totalScore} out of{" "}
            {getTestPaperData?.data?.length}
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
            className="flex cursor-pointer justify-end rounded-lg bg-gray-300 px-3 py-2 fw-bold"
            onClick={() => {
              router(`/home`);
            }}
          >
            Go Back
          </div>
        </div>

        <div className="row">
          <div className={`col-7 p-0 mt-5`}>
            <div className={`${style.card} pb-5`}>
              <div className=" py-4 text-2xl text-white">
                <div className="d-flex justify-content-end  mt-3">
                  <h4 className="black px-3 font-mulish fs-6">
                    Page {currentQuestion + 1} of{" "}
                    {getTestPaperData?.data?.length}
                  </h4>
                </div>
                <div className=" px-4 text-2xl text-black">
                  <div
                    style={{
                      fontSize: 10,
                    }}
                    className={style.htmlContent}
                    dangerouslySetInnerHTML={{
                      __html: getTestPaperData?.data?.[currentQuestion]?.name,
                    }}
                  />
                </div>
              </div>
              {getTestPaperData?.data?.[currentQuestion]?.options?.map(
                (answer, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center  m-1 px-3"
                    >
                      <div className="h6 ">
                        <>
                          {selectedOptions?.[currentQuestion]?.isCorrect ? (
                            <>
                              {selectedOptions?.[currentQuestion]
                                ?.attemptedOption === answer?.option ? (
                                <BsRecordFill
                                  style={{
                                    fontSize: 30,
                                    color: "green",
                                  }}
                                />
                              ) : (
                                <>
                                  <BsRecord
                                    style={{
                                      fontSize: 30,
                                      color: "black",
                                    }}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {selectedOptions?.[currentQuestion]
                                ?.attemptedOption ? (
                                <>
                                  {selectedOptions?.[currentQuestion]
                                    ?.attemptedOption === answer?.option ? (
                                    <BsRecordFill
                                      style={{
                                        fontSize: 30,
                                        color: "red",
                                      }}
                                    />
                                  ) : (
                                    <>
                                      {getTestPaperData?.data?.[currentQuestion]
                                        ?.answer?.option === answer?.option ? (
                                        <>
                                          <BsRecordFill
                                            style={{
                                              fontSize: 30,
                                              color: "green",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <BsRecord
                                            style={{
                                              fontSize: 30,
                                              color: "black",
                                            }}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <BsRecord
                                    style={{
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                        <span
                          className="mx-2"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {answer.text}
                        </span>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            {!!getTestPaperData?.data?.[currentQuestion]?.answer
              ?.explanation ? (
              <div className="my-4 w-full  text-left font-mulish text-xl text-black">
                <div className="font-mulish">Description: </div>
                {getTestPaperData?.data?.[currentQuestion]?.answer?.explanation}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-2"></div>
          <div className="col-3">
            <div className=" ">
              <div className="text-center p-1 border">Questions overview</div>
              <div className="text-center p-1 bg-success text-white h6">
                Correct Answer
              </div>

              <div className="text-center p-1 bg-danger text-white h6">
                InCorrect Answer
              </div>
              <div className="text-center p-1 bg-secondary text-white h6">
                Not Attempted Question
              </div>
              <div className="row d-flex justify-content-center">
                {getTestPaperData?.testPaperDetails?._id ? (
                  <>
                    {getTestPaperData?.testPaperDetails?.questions?.map(
                      (i, index) => {
                        return (
                          <div
                            onClick={() => {
                              setCurrentQuestion(index);
                            }}
                            key={i}
                            className={`  ${style.box} ${
                              selectedOptions?.[index]?.isCorrect
                                ? `bg-success text-white`
                                : selectedOptions?.[index]?.isCorrect === false
                                ? `bg-danger text-white`
                                : `bg-secondary  text-white`
                            }`}
                            style={{
                              width: 30,
                              padding: 5,
                              margin: 5,
                              textAlign: "center",
                              cursor: "pointer",
                              fontSize: 10,
                              position: "relative",
                            }}
                          >
                            {index + 1}
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
