import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo-trans.png";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";
import { useAtom } from "jotai";
import { currentUser } from "../../jotaiStore/user";
import { resendVerificationEmail } from "../../services/user/service";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const Header = (props) => {
  const [user, setUser] = useAtom(currentUser);
  const [loading, setLoading] = useState(false);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const navigate = useNavigate();
  return (
    <header id="header">
      <HeaderTopbar topbarClass={props.topbarClass} />
      {user?._id ? (
        <>
          {user.isVerified ? (
            <></>
          ) : (
            <>
              <div
                className="  bg-warning text-white text-center  text-lg justify-center items-center  "
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <div className=" d-flex justify-content-center align-items-center py-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#000"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                  </svg>
                  <span className="mx-2 text-black">
                    Please verify your email to access all features
                  </span>
                </div>
                <div className="w-100 d-flex py-1 justify-content-center align-items-center ">
                  <div
                    onClick={() => {
                      setLoading("border");
                      resendVerificationEmail()
                        .then(() => {
                          toast.success("Verficaton Email Sent", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            role: "alert",
                          });
                          setLoading("");
                        })
                        .catch((err) => {
                          toast.error(err?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            role: "alert",
                          });
                          setLoading("");
                        });
                    }}
                    role="button"
                    className=" h6 py-2 text-black shadow-lg p-3 rounded  d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex align-items-center">
                      <Spinner
                        as="span"
                        animation={loading}
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <div className="mx-2"> Resend Email</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}

      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/home"
                  >
                    <img src={Logo} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li
                      className="menu-item-has-children"
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <Link onClick={ClickHandler} to="/about">
                        About
                      </Link>
                    </li>
                    <li
                      className="menu-item-has-children"
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <Link onClick={ClickHandler} to="/course">
                        Courses
                      </Link>
                    </li>
                    {user?.isVerified ? (
                      <li
                        className="menu-item-has-children"
                        style={{
                          listStyle: "none",
                        }}
                      >
                        <Link onClick={ClickHandler} to="/enrolled/courses">
                          Enrolled Courses
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    <li
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <Link onClick={ClickHandler} to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-2">
                <div className="header-right">
                  <div className="close-form">
                    {user?._id ? (
                      <>
                        <Link
                          to="/user/profile/dashboard"
                          className=" "
                          onClick={() => {
                            navigate("/user/profile/dashboard");
                          }}
                        >
                          <div class="avatar bg-primary text-white p-3 rounded-circle  ">
                            {`${user?.name?.charAt(0)}${
                              user?.name?.split(" ")?.[1]?.charAt(0)
                                ? user?.name?.split(" ")?.[1]?.charAt(0)
                                : user?.name?.charAt(1)
                            }`}
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          onClick={ClickHandler}
                          className="login"
                          to="/login"
                        >
                          <span className="text">LOGIN</span>
                          <span className="mobile">
                            <i className="fi flaticon-charity"></i>
                          </span>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
