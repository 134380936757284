import React from "react";
import { Link } from "react-router-dom";
// import Logo from '../../images/logo-s2.svg'
import Logo from "../../images/logo-trans.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/home"
                  >
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <p>
                  At 2BRCIC, we pave the way for success in the field of
                  immigration consulting through comprehensive training and
                  mentorship.
                </p>
                {/* <div className="social">
                  <ul>
                    <li
                       style={{
                        listStyle: "none",
                      }}
                    >
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-google"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/home">
                      Home
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/about">
                      About Us
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/course">
                      Courses
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/contact">
                      Contact
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/blog">
                      Latest News
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget s2">
                <div className="widget-title">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/course">
                      Courses
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/lesson">
                      Lesson
                    </Link>
                  </li> */}
                  {/* <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/register">
                      Sign Up
                    </Link>
                  </li> */}
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/testimonial">
                      Testimonials
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-contact-widget">
                <div className="widget-title">
                  <h3>Contact Us</h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <i className="fi flaticon-email"></i>
                      media@2brcic.ca
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <i className="fi flaticon-phone-call"></i>(437) 221-5085{" "}
                      {/* <br />
                      (704) 555-0127 */}
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <i className="fi flaticon-placeholder"></i>80 Maritime,
                      Ontario Blvd. <br /> Brampton, Canada
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-6 col-md-12 col-12">
              <ul>
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  &copy; 2024{" "}
                  {/* <Link onClick={ClickHandler} to="/">
                    KTS
                  </Link> */}
                  All rights reserved.
                </li>
              </ul>
            </div>
            <div className="col col-lg-6 col-md-12 col-12">
              <div className="link">
                <ul>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/privacy">
                      Privace & Policy
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/terms">
                      Terms
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/about">
                      About us
                    </Link>
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                    }}
                  >
                    <Link onClick={ClickHandler} to="/faq">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
