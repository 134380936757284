import React, { useState } from "react";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "./style.css";
import { currentUser } from "../../jotaiStore/user";
import { useAtom } from "jotai";
export const menuClasses = {
  root: "ps-menu-root",
  menuItemRoot: "ps-menuitem-root",
  subMenuRoot: "ps-submenu-root",
  button: "ps-menu-button",
  prefix: "ps-menu-prefix",
  suffix: "ps-menu-suffix",
  label: "ps-menu-label",
  icon: "ps-menu-icon",
  subMenuContent: "ps-submenu-content",
  SubMenuExpandIcon: "ps-submenu-expand-icon",
  disabled: "ps-disabled",
  active: "ps-active",
  open: "ps-open",
};
const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const SidebarMenu = ({
  menu,
  setMenu,
  isMobile,
  result,
  setSelectedTopic,
  selectedTopic,
  setSelectedIndex,
  selectedIndex,
  setSelectPracticePaper,
  selectPracticePaper,
}) => {
  const [theme, setTheme] = React.useState("light");
  const [user] = useAtom(currentUser);

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const menuItemStyles = {
    icon: {
      color: themes[theme].menu.icon,
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? hexToRgba(themes[theme].menu.menuContent) : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor),
        color: themes[theme].menu.hover.color,
      },
    },

    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
    psMenuItemRoot: {
      listStyle: "none",
    },
    psSubMenuRoot: {
      listStyle: "none",
    },
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "scroll initial",
        border: "none",
        outline: "none",
      }}
    >
      {!menu?.open && !isMobile ? (
        <div
          className=" btn-primary "
          style={{
            width: "30px",
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            setMenu({
              ...menu,
              open: !menu.open,
            })
          }
        >
          {menu?.open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="white"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          )}
        </div>
      ) : (
        <></>
      )}
      <Sidebar
        transitionDuration={500}
        collapsedWidth={menu?.collapseWidth}
        collapsed={!menu?.open}
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor)}
        rootStyles={{
          color: themes[theme].sidebar.color,
          border: !menu?.open ? "none" : "1px solid #e4e4e4",
          width: 400,
        }}
      >
        <div>
          <div class="position-relative w-100 bg-blue">
            <div
              class=" p-3 d-flex items-center justify-content-between"
              style={{
                backgroundColor: "#0b84e3",
              }}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {result?._id}
              </div>

              <div
                className=" btn-primary rounded-circle cursor-pointer"
                style={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setMenu({
                    ...menu,
                    open: !menu.open,
                  })
                }
              >
                {!menu?.open ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="white"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <Menu
            menuItemStyles={menuItemStyles}
            rootStyles={{
              color: themes[theme].sidebar.color,
              marginBlock: 20,
            }}
          >
            {menu?.open ? (
              <>
                {result?.lessons?.map((lesson, index) => {
                  const allTopicsId = lesson?.topics?.map((i) => i._id);

                  const isTopicAllCompleted = allTopicsId.every((i) =>
                    user?.topics?.includes(i)
                  );
                  const isLessonCompleted = lesson?.testCompleted?.length
                    ? lesson?.testCompleted?.includes(user?._id)
                    : true;

                  const isAllCompleted =
                    isTopicAllCompleted && isLessonCompleted;

                  return (
                    <SubMenu
                      defaultOpen={selectedIndex === lesson?._id}
                      // disabled={lesson.topics?.length ? false : true}
                      className="border-bottom my-2"
                      key={lesson?._id}
                      label={
                        <div
                          onClick={() => {
                            setSelectedIndex(lesson?._id);
                            setSelectedTopic("");
                            setSelectPracticePaper([]);
                          }}
                          className="lessonName h6"
                          style={{
                            color:
                              selectedIndex === lesson?._id ? "#0b84e3" : "",
                          }}
                        >
                          {lesson.lessonName}
                        </div>
                      }
                      icon={
                        isAllCompleted ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#0b84e3"
                            class="bi bi-check-circle-fill"
                            viewBox="0 0 17 17"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#0b84e3"
                            class="bi bi-circle"
                            viewBox="0 0 20 20"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          </svg>
                        )
                      }
                    >
                      <MenuItem width={400} icon={<></>}>
                        <div className="d-flex align-items-center " style={{}}>
                          <>
                            <div
                              className=""
                              style={{
                                textTransform: "capitalize",
                                color: "#0b84e3",
                                fontSize: 15,
                              }}
                            >
                              {lesson.topics?.length} Topics{" "}
                              <span className="mx-1">:</span>
                            </div>
                          </>

                          <div
                            style={{
                              textTransform: "capitalize",
                              color: "#0b84e3",
                              fontSize: 15,
                            }}
                          >
                            {lesson.practicePaper?.length ? 1 : 0} Quiz
                          </div>
                        </div>
                      </MenuItem>
                      {lesson.topics.map((topic) => {
                        return (
                          <MenuItem
                            width={400}
                            icon={
                              user?.topics?.includes(topic?._id) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#0b84e3"
                                  class="bi bi-check-circle-fill"
                                  viewBox="0 0 17 17"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="#0b84e3"
                                  class="bi bi-circle"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                </svg>
                              )
                            }
                          >
                            <div
                              className="h6 fw-normal"
                              onClick={() => {
                                setSelectedTopic(topic);
                                setSelectPracticePaper([]);
                                setSelectedIndex(lesson?._id);
                              }}
                              style={{
                                // fontFamily: "blockquote",
                                textTransform: "capitalize",
                                color:
                                  selectedTopic?.name === topic.name
                                    ? "#0b84e3"
                                    : "",
                                fontWeight:
                                  selectedIndex === lesson?._id ? "bold" : "",
                              }}
                            >
                              {topic.name}
                            </div>
                          </MenuItem>
                        );
                      })}
                      {lesson?.practicePaper?.length ? (
                        <MenuItem
                          width={400}
                          icon={
                            !lesson?.testCompleted?.includes(user?._id) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="#0b84e3"
                                class="bi bi-clipboard-check"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"
                                />
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#0b84e3"
                                class="bi bi-check-circle-fill"
                                viewBox="0 0 17 17"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            )
                          }
                        >
                          <div
                            className="d-flex align-items-center"
                            role="button"
                            onClick={() => {
                              if (
                                user?.practicePaperAccess &&
                                !user?.lessonAccess
                              ) {
                                setSelectPracticePaper(lesson.practicePaper);
                                setSelectedIndex(lesson?._id);
                                setSelectedTopic({});
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }
                              if (
                                user?.practicePaperAccess &&
                                user?.lessonAccess
                              )
                                if (isTopicAllCompleted) {
                                  setSelectPracticePaper(lesson.practicePaper);
                                  setSelectedIndex(lesson?._id);
                                  setSelectedTopic({});
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                } else {
                                  alert(
                                    "Please complete all the topics to unlock the practice paper."
                                  );
                                }
                            }}
                          >
                            <div
                              className="fw-bold"
                              style={{
                                color: "#3a93e6",
                                fontSize: 16,
                              }}
                            >
                              Practice Question
                            </div>
                          </div>
                        </MenuItem>
                      ) : (
                        <></>
                      )}
                    </SubMenu>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};

export default SidebarMenu;
