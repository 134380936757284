import React from "react";
import "./style.css";
import Logo from "../../images/logo-trans.png";
import { ProgressBar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { currentUser } from "../../jotaiStore/user";
import { useAtom } from "jotai";

const Header = ({
  setMenu,
  menu,
  getLessonsList,
  setSelectedIndex,
  selectedIndex,
  setSelectedTopic,
}) => {
  const navigate = useNavigate();
  const [user] = useAtom(currentUser);
  const totalTopics = getLessonsList?.lessons?.flatMap((i) =>
    i.topics?.map((i) => i._id)
  );
  const topicsCompletedByUser = totalTopics?.filter((i) =>
    user?.topics?.includes(i)
  )?.length;
  let percentage = 0;
  const practicePaperCompleted = getLessonsList?.lessons?.filter((i) =>
    i?.testCompleted?.includes(user?._id)
  )?.length;

  if (getLessonsList?.lessons?.length) {
    if (user?.practicePaperAccess && !user?.lessonAccess) {
      percentage =
        (practicePaperCompleted /
          getLessonsList?.lessons?.filter((i) => i?.practicePaper?.length)
            ?.length) *
        100;
    }
  }
  if (totalTopics?.length) {
    if (user?.practicePaperAccess && user?.lessonAccess) {
      percentage = Math.round(
        (topicsCompletedByUser / totalTopics?.length) * 100
      );
    }
  }

  return (
    <>
      <div className="">
        {/* Desktop View */}
        <div className="d-none d-md-flex    border-bottom ">
          <div
            className="col-md-2 d-flex justify-content-center align-items-center"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={Logo} alt="logo" className="w-50 h-75" />
          </div>
          <div className="col-md-4 border-start d-flex flex-column justify-content-center align-items-center  p-4">
            <div className="w-100">
              <div className="d-flex  w-100">
                <div>{percentage?.toFixed(0)}% COMPLETE </div>
                <div className="mx-3">
                  {user?.practicePaperAccess && !user?.lessonAccess
                    ? `${practicePaperCompleted}/${
                        getLessonsList?.lessons?.filter(
                          (i) => i?.practicePaper?.length
                        )?.length
                      } Papers`
                    : user?.practicePaperAccess && user?.lessonAccess
                    ? `${topicsCompletedByUser}/${totalTopics?.length} Topics`
                    : ""}
                </div>
              </div>

              <div className="w-100">
                <ProgressBar
                  now={percentage}
                  variant="success"
                  animated
                  visuallyHidden={false}
                  style={{
                    borderRadius: 5,
                    height: 8,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 border-start d-flex justify-content-center align-items-center">
            <div className="">
              <div
                className="d-flex align-items-center"
                onClick={() => {
                  setSelectedTopic({});
                  const getLessonIndex = getLessonsList?.lessons?.findIndex(
                    (i) => i?._id === selectedIndex
                  );
                  const getNextLesson =
                    getLessonsList?.lessons?.[getLessonIndex - 1];

                  if (getNextLesson) {
                    setSelectedIndex(getNextLesson?._id);
                  }
                }}
                role="button"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#0b84e3"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                    />
                  </svg>
                </div>
                <div
                  className="mx-2"
                  style={{
                    color: "#0b84e3",
                    fontWeight: "bold",
                  }}
                >
                  Previous Lesson
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 border-start d-flex justify-content-center align-items-center">
            <div
              role="button"
              className=""
              onClick={() => {
                setSelectedTopic({});
                const getLessonIndex = getLessonsList?.lessons?.findIndex(
                  (i) => i?._id === selectedIndex
                );
                const getNextLesson =
                  getLessonsList?.lessons?.[getLessonIndex + 1];

                if (getNextLesson) {
                  setSelectedIndex(getNextLesson?._id);
                }
              }}
            >
              <div className="d-flex align-items-center">
                <div
                  className="mx-2"
                  style={{
                    fontSize: 17,
                    color: "#0b84e3",
                    fontWeight: "bold",
                  }}
                >
                  Next Lesson
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#0b84e3"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 border-start d-flex justify-content-center align-items-center">
            <div
              className="w-100"
              style={{
                cursor: "pointer",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-evenly w-100"
                onClick={() => {
                  navigate("/user/profile/dashboard");
                }}
              >
                <div>{user?.name}</div>
                <Link to="/user/profile/dashboard" className=" ">
                  <div class="avatar bg-primary text-white p-2 rounded-circle  ">
                    {`${user?.name?.charAt(0)}${
                      user?.name?.split(" ")?.[1]?.charAt(0)
                        ? user?.name?.split(" ")?.[1]?.charAt(0)
                        : user?.name?.charAt(1)
                    }`}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View */}
        <div className="d-md-none d-flex flex-wrap ">
          <div
            className="col-3 d-flex justify-content-center align-items-center border-bottom"
            onClick={() => {
              setMenu({
                open: !menu.open,
                collapseWidth: 0,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#0b84e3"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </div>
          <div className="col-6 border-bottom">
            <div className="d-flex flex-column justify-content-center align-items-start border-start p-3">
              <div className="d-flex  ">
                <div>{percentage?.toFixed(0)}% COMPLETE </div>
                <div className="mx-3">
                  {user?.practicePaperAccess && !user?.lessonAccess
                    ? `${practicePaperCompleted}/${
                        getLessonsList?.lessons?.filter(
                          (i) => i?.practicePaper?.length
                        )?.length
                      } Papers`
                    : user?.practicePaperAccess && user?.lessonAccess
                    ? `${topicsCompletedByUser}/${totalTopics?.length} Topics`
                    : ""}
                </div>
              </div>

              <div className="w-100">
                <ProgressBar
                  now={percentage}
                  variant="success"
                  animated
                  visuallyHidden={false}
                  style={{
                    borderRadius: 5,
                    height: 8,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-3 border-start d-flex align-items-center justify-content-center border-bottom">
            <div className="">
              <div className="d-flex align-items-center justify-content-evenly">
                <Link
                  to="/user/profile/dashboard"
                  className=" "
                  onClick={() => {
                    navigate("/user/profile/dashboard");
                  }}
                >
                  <div class="avatar bg-primary text-white p-2 rounded-circle  ">
                    {`${user?.name?.charAt(0)}${
                      user?.name?.split(" ")?.[1]?.charAt(0)
                        ? user?.name?.split(" ")?.[1]?.charAt(0)
                        : user?.name?.charAt(1)
                    }`}
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-6 d-flex  align-items-center justify-content-center border-bottom border-end py-3 ">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => {
                setSelectedTopic({});
                const getLessonIndex = getLessonsList?.lessons?.findIndex(
                  (i) => i?._id === selectedIndex
                );
                const getNextLesson =
                  getLessonsList?.lessons?.[getLessonIndex - 1];

                if (getNextLesson) {
                  setSelectedIndex(getNextLesson?._id);
                }
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#0b84e3"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <div
                className="mx-2"
                style={{
                  color: "#0b84e3",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Previous Lesson
              </div>
            </div>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-center  py-3 border-bottom ">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => {
                setSelectedTopic({});
                const getLessonIndex = getLessonsList?.lessons?.findIndex(
                  (i) => i?._id === selectedIndex
                );
                const getNextLesson =
                  getLessonsList?.lessons?.[getLessonIndex + 1];

                if (getNextLesson) {
                  setSelectedIndex(getNextLesson?._id);
                }
              }}
            >
              <div
                className="mx-2"
                style={{
                  fontSize: 15,
                  color: "#0b84e3",
                  fontWeight: "bold",
                }}
              >
                Next Lesson
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#0b84e3"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
