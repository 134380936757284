import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast, Zoom } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo-trans.png";
import { signUp } from "../../services/auth/service";
import loadingGif from "../../images/loading1.gif";
import "./style.scss";
import { currentUser } from "../../jotaiStore/user";
import { useAtom } from "jotai";
import { setItem } from "../../utils/dataService/localStorageControl";

const SignUpPage = ({ modal, setModal, addCourseFun }) => {
  const [loading, setLoading] = useState(false);
  const [, setUser] = useAtom(currentUser);

  const navigate = useNavigate();

  const [value, setValue] = useState({
    email: "",
    full_name: "",
    password: "",
    mobile: "",
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const submitForm = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setLoading(true);
      signUp({
        body: {
          name: value.full_name,
          email: value.email,
          password: value.password,
          phone: value.mobile,
        },
      })
        .then((res) => {
          setLoading(false);
          toast.success("Verification link has been sent to your email", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Zoom,
            role: "alert",
          });
          setUser(res?.data?.data?.payload);
          setItem("access_token", res?.data?.data?.accessToken);
          setItem("refresh_token", res?.data?.data?.refreshToken);
          if (modal) {
            addCourseFun(modal);
            setModal("");
            return;
          } else {
            navigate("/user/profile/dashboard");
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Zoom,
            role: "alert",
          });
        });
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };

  return (
    <Grid className={`${modal ? "courseWrapper" : "loginWrapper"}`}>
      <Grid className="loginForm">
        {modal ? (
          <></>
        ) : (
          <Link
            className="navbar-brand"
            to="/home"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="logo" />
          </Link>
        )}

        <h2>Signup</h2>
        <p>Signup your account</p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Full Name"
                value={value.full_name}
                variant="outlined"
                name="full_name"
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("full name", value.full_name, "required")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Mobile Number"
                value={value.mobile}
                type="tel"
                variant="outlined"
                name="mobile"
                label="Mobile Number"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("mobile", value.mobile, "required")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="E-mail"
                value={value.email}
                variant="outlined"
                name="email"
                label="E-mail"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("email", value.email, "required|email")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Password"
                value={value.password}
                variant="outlined"
                name="password"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("password", value.password, "required")}
            </Grid>
            <Grid item xs={12}>
              <Grid className="formFooter">
                <Button
                  fullWidth
                  className="cBtn cBtnLarge cBtnTheme"
                  type="submit"
                >
                  {loading ? (
                    <img
                      src={loadingGif}
                      alt="loading"
                      style={{ width: 20, marginRight: 10 }}
                    />
                  ) : (
                    <></>
                  )}
                  Sign Up
                </Button>
              </Grid>

              <p className="noteHelp">
                Already have an account?{" "}
                <Link to="/login">Return to Sign In</Link>
              </p>
            </Grid>
          </Grid>
        </form>
        <div className="shape-img">
          <i className="fi flaticon-honeycomb"></i>
        </div>
      </Grid>
    </Grid>
  );
};

export default SignUpPage;
